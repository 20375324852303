import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import * as _ from 'lodash';
import { NgxPermissionsService } from 'ngx-permissions';
import { BehaviorSubject, Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AppModulesType } from '../enums/appModulesType.enum';
import { OriginUrl } from '../enums/originUrl.enum';
import { ReservationType } from '../enums/schedule/reservationType.enum';
import { ActionButton, ContractorSettings, Customer, MenuItem, User } from '../model';
import { BaseState } from './../state/base/base.state';
import { ActiveModulesService } from './activeModules.service';
import { HelperMedicalService } from './helper-medical.service';
import { StorageService } from './storage.service';

@Injectable({
    providedIn: 'root',
})
export class MenuService {
    public default: MenuItem[] = [
        // {
        //     link: '/index',
        //     title: 'Domov',
        //     id: 'INDEX',
        //     icon: 'la la-h-square',
        // },
        {
            link: '/calendar/main',
            title: 'Termini',
            id: 'TERMS-CALENDAR',
            icon: 'la la-calendar',
            rules: 'menu_main_calendar__Visible',
        },
        {
            link: '/waiting-queue',
            title: 'Čakalna vrsta',
            onlyTest: false,
            id: 'WAITING-QUEUE',
            icon: 'la la-clock-o',
            rules: 'menu_main_waiting_queue__Visible',
            module: AppModulesType.WAITING_QUEUE,
        },
        {
            link: '/case',
            title: 'Avtorizacije',
            onlyTest: false,
            id: 'CASE',
            icon: 'la la-stethoscope',
            rules: 'menu_main_case__Visible',
            module: AppModulesType.CASE,
        },
        // {
        //     link: '/inbox/device',
        //     title: 'Naprave',
        //     onlyTest: false,
        //     id: 'INBOX-DEVICE',
        //     icon: 'la la-inbox',
        //     rules: 'menu_main_inbox__Visible',
        // },
        {
            link: '/inbox',
            title: 'Prejeto',
            onlyTest: false,
            id: 'INBOX',
            icon: 'la la-inbox',
            rules: 'menu_main_inbox__Visible',
            statusIcon: 'newInboxItems',
            module: AppModulesType.INBOX,
            // submenu: [{ title: 'Naprave', link: '/inbox/device', id: 'INBOX-DEVICE' }], //la-sitemap
        },
        {
            link: '/treatments',
            title: 'Zdravljenja',
            id: 'TREATMENTS',
            icon: 'la la-heart-o',
            rules: 'menu_main_treatments__Visible',
            module: AppModulesType.TREATMENTS,
        },
        // {
        //     link: '/consul',
        //     title: 'eTriaža',
        //     id: 'TRIAGE',
        //     icon: 'la la-lightbulb-o',
        //     rules: 'menu_main_triage__Visible',
        // },
        {
            link: '/customers',
            title: 'Stranke',
            id: 'CUSTOMERS',
            icon: 'la la-street-view',
            rules: 'menu_main_customers__Visible',
        },
        {
            link: '/tasks',
            title: 'Opravila',
            rules: 'menu_main_tasks__Visible',
            id: 'TASKS',
            icon: 'la la-check-square',
        },
        {
            link: '/accounting/list',
            title: 'Zaračunavanje',
            rules: 'menu_main_accountingVisible',
            id: 'ACCOUNTING',
            icon: 'la la-money',
            tabMenuId: 'accounting',
            module: AppModulesType.ACCOUNTING,
        },
        {
            link: '/communication',
            title: 'Komunikacija',
            rules: 'menu_main_communicationVisible',
            id: 'COMMUNICATION',
            icon: 'la la-comments',
        },
        {
            link: '/reports/general',
            title: 'Poročila',
            id: 'REPORTS',
            icon: 'la la-pie-chart',
            rules: 'menu_main_reports__Visible',
            module: AppModulesType.REPORTS,
        },
        {
            link: '/halo-doc',
            title: 'ePosvet',
            id: 'HELLO_DOCTOR',
            icon: 'la la-h-square',
            statusIcon: 'halloDoc-checkbox',
            module: AppModulesType.HELLO_DOCTOR,
        },
        {
            link: '/subcontractor-availability',
            title: 'Plan dela',
            id: 'SUBCONTRACTOR_AVAILABILITY',
            icon: 'la la-tasks',
            statusIcon: 'halloDoc-checkbox',
            module: AppModulesType.SUBCONTRACTOR_AVAILABILITY,
        },
        {
            action: 'openTelephonyPopup',
            title: 'Telefonija',
            id: 'TELEPHONY',
            icon: 'la la-tty',
            rules: 'menu_main_telephony__Visible',
            module: AppModulesType.TELEPHONY,
        },
        {
            link: '/marketing',
            title: 'Naročanje',
            id: 'MARKETING',
            icon: 'la la-signal',
            rules: 'menu_main_marketing__Visible',
            tabMenuId: 'marketing',
            module: AppModulesType.MARKETING,
        },
        {
            link: '/oim',
            title: 'OIM',
            id: 'OIM_TRACKING',
            icon: 'la la-tasks',
            tabMenuId: 'oimTracking',
            module: AppModulesType.OIM_TRACKING,
        },
        {
            link: '/documentation',
            title: 'Dokumentacija',
            id: 'DOCUMENTATION_OVERVIEW',
            icon: 'la la-file',
            rules: 'caleandar_view_terms__visible',
            module: AppModulesType.DOCUMENTATION_OVERVIEW,
        },
    ].map(el => new MenuItem().deserialize(el));

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public terms: any = [
        {
            link: '/calendar/selected/',
            title: 'Podrobnosti',
            id: 'TERMS/SELECTED',
        },
        {
            link: '/calendar/visit/communication/',
            title: 'Komunikacija',
            id: 'TERMS/VISIT/COMMUNICATION',
        },
        {
            link: '/dentist/teeth-status/',
            title: 'Status zob',
            rules: 'menu_main_dentist__Visible',
            id: 'DENTIST',
            module: AppModulesType.DENTIST,
        },
    ];
    // http://localhost:4200/terms/visit/communication
    // http://localhost:4200/terms/selected/936eab0d-d4f1-4139-a52f-227a2f935b79
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public visit: any = [
        {
            link: '/calendar/selected/',
            title: 'Podrobnosti',
            id: 'VISIT/TERMS/SELECTED',
        },
        // {
        //     link: '/terms/visit/portfolio',
        //     title: 'Listine',
        //     id: 'VISIT/TERMS/VISIT/PORTFOLIO',
        // },
        // {
        //     link: '/terms/visit/result',
        //     title: 'Izvidi',
        //     id: 'VISIT/TERMS/VISIT/RESULT'
        // },
        {
            link: '/calendar/visit/services/',
            title: 'Storitve',
            id: 'VISIT/TERMS/VISIT/SERVICES',
        },
        {
            link: '/calendar/visit/communication/',
            title: 'Komunikacija',
            id: 'VISIT/TERMS/VISIT/COMMUNICATION',
        },
    ];

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public hdVisit: any = [
        {
            link: '/calendar/selected/hd/',
            title: 'MF Medical',
            id: 'VISIT/TERMS/SELECTED/HD',
        },
        {
            link: '/calendar/selected/',
            title: 'Podrobnosti',
            id: 'VISIT/TERMS/SELECTED',
        },
        // {
        //     link: '/terms/visit/portfolio',
        //     title: 'Listine',
        //     id: 'VISIT/TERMS/VISIT/PORTFOLIO',
        // },
        // {
        //     link: '/terms/visit/result',
        //     title: 'Izvidi',
        //     id: 'VISIT/TERMS/VISIT/RESULT'
        // },
        {
            link: '/calendar/visit/services/',
            title: 'Storitve',
            id: 'VISIT/TERMS/VISIT/SERVICES',
        },
        {
            link: '/calendar/visit/communication/',
            title: 'Komunikacija',
            id: 'VISIT/TERMS/VISIT/COMMUNICATION',
        },
    ];

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public customer: any = [
        {
            link: '/customers/customers/view/',
            title: 'Podrobnosti',
            id: 'CUSTOMERS/VIEW',
        },
        {
            link: '/customers/customers/history/',
            title: 'Vse obravnave',
            id: 'CUSTOMERS/HISTORY',
        },
        {
            link: '/customers/customers/surveys/',
            title: 'Vprašalniki',
            id: 'CUSTOMERS/SURVEYS',
        },
        {
            link: '/customers/customers/link/',
            title: 'Povezovanje',
            id: 'CUSTOMERS/LINK',
        },
    ];

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public services: any = [
        {
            link: '/settings/services/',
            title: 'Storitev',
            routerActiveExact: true,
        },
        {
            link: '/settings/examination-services/',
            title: 'Preiskave',
            routerActiveExact: true,
            module: AppModulesType.OCCUPATIONAL_MEDICINE,
        },
        {
            link: '/settings/services-groups/',
            title: 'Skupina storitev',
            routerActiveExact: true,
        },
        {
            link: '/settings/examination-groups/',
            title: 'Skupina preiskav',
            routerActiveExact: true,
            module: AppModulesType.OCCUPATIONAL_MEDICINE,
        },
    ];

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public haloDoc: any = [
        {
            link: '/halo-doc/',
            title: 'Razpored',
            routerActiveExact: true,
        },
        // {
        //     link: '/halo-doc/doctors',
        //     title: 'Zdravniki',
        //     rules: 'hallo_doctor_doctors__Visible',
        //     routerActiveExact: true,
        // },
        {
            link: '/halo-doc/reports',
            title: 'Poročila',
            rules: 'hallo_doctor_reports__Visible',
            routerActiveExact: false,
        },
        {
            link: '/halo-doc/call-history',
            title: 'Zgodovina klicev',
            rules: 'hallo_doctor_call_history__Visible',
            routerActiveExact: true,
        },
    ];

    public accounting: MenuItem[] = [
        {
            link: '/accounting/list',
            title: 'Računi',
            // id: 'ACCOUNTING/LIST',
            id: 'accoutingViewList',
            rules: 'menu_main_accountingVisible',
        },
        {
            link: '/accounting/reports/',
            title: 'Izpisi',
            id: 'accoutingViewReports',
            // id: 'ACCOUNTING/REPORTS',
            rules: 'menu_main_accountingVisible',
        },
        {
            link: '/accounting/reports-advanced',
            title: 'Napredni izpisi',
            id: 'accoutingViewReportsAdvanced',
            // id: 'ACCOUNTING/REPORTS-ADVANCED',
            rules: 'menu_main_accountingVisible',
        },
        {
            link: '/accounting/group-invoice/',
            title: 'Zbirni račun',
            id: 'accoutingViewGroupInvoice',
            // id: 'ACCOUNTING/GROUP-INVOICE',
            rules: 'menu_main_accountingVisible',
        },
        {
            link: '/accounting/unprocessed/',
            title: 'Neobdelani',
            id: 'accoutingViewUnprocessed',
            // id: 'ACCOUNTING/UNPROCESSED',
            rules: 'menu_main_accountingVisible',
        },
        {
            link: '/accounting/web-payments/',
            title: 'Spletna plačila',
            id: 'accoutingViewWebpayment',
            rules: 'menu_main_accountingVisible',
        },
        {
            link: '/accounting/coupons/',
            title: 'Vrednostni boni',
            id: 'accoutingViewCoupons',
            // id: 'ACCOUNTING/COUPONS',
            rules: 'menu_main_accountingVisible',
        },
        {
            link: '/accounting/partners/',
            title: 'Partnerji',
            id: 'accoutingViewPartners',
            // id: 'ACCOUNTING/PARTNERS',
            rules: 'menu_main_accountingVisible',
        },

        // accoutingViewList
        // accoutingViewReports
        // accoutingViewReportsAdvanced
        // accoutingViewGroupInvoice
        // accoutingViewUnprocessed
        // accoutingViewWebpayment
        // accoutingViewPayers
        // accoutingViewCoupons
        // accoutingViewPartners
        // accoutingViewWebCoupons
    ].map(el => new MenuItem().deserialize(el));

    public inbox: MenuItem[] = [
        // {
        //     link: '/inbox',
        //     title: 'Povzetek',
        //     id: 'INBOX/DASHBOARD',
        //     routerActiveExact: true,
        // },
        {
            link: '/inbox/devices',
            title: 'Naprave',
            id: 'inboxDevices',
            routerActiveExact: true,
            module: AppModulesType.DEVICES_INBOX,
        },
        {
            link: '/inbox/consul',
            title: 'eTriaža',
            id: 'inboxConsul',
            routerActiveExact: false,
            displayValue: '0',
            module: AppModulesType.TRIAGE,
        },

        {
            link: '/inbox/case/i',
            title: 'Avtorizacije',
            id: 'inboxCase',
            routerActiveExact: false,
            module: AppModulesType.CASE,
        },
        {
            link: '/inbox/eposvet',
            title: 'ePosvet',
            id: 'inboxEposvet',
            routerActiveExact: false,
            displayValue: '0',
            module: AppModulesType.TELEMEDICINE,
        },
        // {
        //     link: '/inbox/inquiries',
        //     title: 'Povpraševanja',
        //     id: 'INBOX/WIDGET_INQUIRIES',
        //     routerActiveExact: true,
        // },
        {
            link: '/inbox/external-booking',
            title: 'Rezervacije',
            id: 'inboxExternalBooking',
            routerActiveExact: false,
            module: AppModulesType.WIDGETS,
        },
        {
            link: '/inbox/surveys',
            title: 'Vprašalniki',
            id: 'inboxSurveys',
            routerActiveExact: false,
            displayValue: '0',
            module: AppModulesType.SURVEYS,
        },
    ].map(el => new MenuItem().deserialize(el));
    //če hočeš submenu: NE definiraj link in DODAJ submenu
    // Primer: (submenu z 2 linkoma)
    // title: 'Obvestila',
    // rules: 'menu_settings_notifications',
    // id: 'SETTINGS/SEND-CONTENT',
    // submenu: [
    //     { title: 'Vsebina', link: '/settings/send-content' },
    //     { title: 'Pravila', link: '/settings/send-rules' },
    // ],
    public settingsMenuList: MenuItem[] = [
        {
            link: '/settings/users',
            title: 'Uporabniki',
            rules: 'menu_main_settings_users__Visible',
            id: 'SETTINGS/USERS',
        },
        {
            link: '/settings/services',
            title: 'Storitve',
            rules: 'menu_main_settings_services__Visible',
            id: 'SETTINGS/SERVICES',
        },
        {
            link: '/settings/clinic',
            title: 'Ambulanta',
            rules: 'menu_main_settings_clinic__Visible',
            id: 'SETTINGS/CLINIC',
        },
        {
            link: '/settings/accounting',
            title: 'Računovodstvo',
            rules: 'menu_main_accountingVisible',
            id: 'SETTINGS/ACCOUNTING',
            module: AppModulesType.ACCOUNTING,
        },
        {
            link: '/settings/partners',
            title: 'Partnerji',
            rules: 'menu_main_settings_clinic__Visible',
            id: 'SETTINGS/PARTNERS',
        },
        {
            link: '/settings/printer',
            title: 'Tiskanje',
            rules: 'menu_main_settings_printer__Visible',
            id: 'SETTINGS/PRINTER',
        },
        {
            link: '/settings/availability',
            title: 'Razpoložljivost',
            rules: 'menu_main_settings_availability__Visible',
            id: 'SETTINGS/AVAILABILITY',
        },
        {
            title: 'Obveščanje',
            rules: 'menu_main_settings_sendContent__Visible',
            id: 'SETTINGS/SEND-CONTENT',
            link: '/settings/send-content',
            // submenu: [
            //     { title: 'Vsebina', link: '/settings/send-content' },
            //     { title: 'Pravila', link: '/settings/send-rules' },
            // ],
        },
        {
            title: 'Obrazci',
            rules: 'menu_main_settings_sendContent__Visible',
            id: 'SETTINGS/FORMS',
            link: '/settings/forms',
            module: AppModulesType.SURVEYS,
            // submenu: [
            //     { title: 'Vsebina', link: '/settings/send-content' },
            //     { title: 'Pravila', link: '/settings/send-rules' },
            // ],
        },
    ].map(el => new MenuItem().deserialize(el));

    public oimTracking: MenuItem[] = [
        {
            link: '/oim/kanban',
            title: 'Pregled',
            id: 'OIM/KANBAN',
            module: AppModulesType.OIM_TRACKING,
        },
        {
            link: '/oim/all-orders',
            title: 'Vsa naročila',
            id: 'OIM/ALL-ORDERS',
            rules: 'oim_tracking_all_orders__Visible',
            module: AppModulesType.OIM_TRACKING,
        },
    ].map(el => new MenuItem().deserialize(el));

    public marketing: MenuItem[] = [
        {
            link: '/marketing/inquiries',
            title: 'Povpraševanja',
            id: 'MARKETING/INQUIRIES',
            routerActiveExact: false,
            displayValue: '0',
            module: AppModulesType.MARKETING,
        },
        {
            link: '/marketing/web-coupons',
            title: 'Spletni kuponi',
            routerActiveExact: true,
            module: AppModulesType.WEB_COUPONS,
        },
        {
            link: '/marketing/leads',
            title: 'Potencialne stranke',
            id: 'MARKETING/LEADS',
            routerActiveExact: true,
            module: AppModulesType.LEADS,
        },
        {
            link: '/marketing/gdpr-consent',
            title: 'GDPR privolitve',
            id: 'MARKETING/GDPR-CONSENT',
            rules: 'marketing_gdpr_consents__Visible',
            routerActiveExact: true,
            module: AppModulesType.MARKETING,
        },
    ].map(el => new MenuItem().deserialize(el));

    public availability: MenuItem[] = [
        {
            link: '/settings/availability',
            title: 'Razpoložljivost',
            id: 'SETTINGS/AVAILABILITY',
            module: AppModulesType.OIM_TRACKING,
        },
        {
            link: '/settings/planning',
            title: 'Planiranje',
            id: 'SETTINGS/PLANNING',
            // rules: 'marketing_gdpr_consents__Visible',
            module: AppModulesType.HELLO_DOCTOR,
        },
    ].map(el => new MenuItem().deserialize(el));

    public reports: MenuItem[] = [
        {
            link: '/reports/general',
            title: 'Splošno',
            id: 'reportsGeneral',
            rules: 'menu_main_reports__Visible',
        },
        {
            link: '/reports/surveys',
            title: 'Ankete',
            id: 'reportsSurveys',
            module: AppModulesType.SURVEYS,
        },
        {
            link: '/reports/eszbo',
            title: 'eSZBO statistika',
            id: 'reportsEszbo',
            module: AppModulesType.ESZBO,
        },
        {
            link: '/reports/generali-surveys',
            title: 'Ankete Generali',
            id: 'reportsGeneraliSurveysStatistics',
            rules: 'menu_main_reports_generali_surveys__Visible',
        },
    ].map(el => new MenuItem().deserialize(el));

    public customersAndLegalEntities: MenuItem[] = [
        {
            link: '/customers/customers',
            title: 'Stranke',
            id: 'CUSTOMERS/CUSTOMERS',
        },
        {
            link: '/customers/legal-entities',
            title: 'Pravne osebe',
            id: 'CUSTOMERS/LEGAL-ENTITIES',
            module: AppModulesType.ACCOUNTING,
        },
    ].map(el => new MenuItem().deserialize(el));

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public hiddeHeader: BehaviorSubject<any> = new BehaviorSubject(false);
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public menuList: BehaviorSubject<any[]> = new BehaviorSubject([]);
    public customerInMenu: Subject<Customer> = new Subject();
    public actionButtonsMenu: Subject<ActionButton[]> = new BehaviorSubject([]);

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public subTitle: BehaviorSubject<any> = new BehaviorSubject('');

    constructor(
        private storage: StorageService,
        private permissionsService: NgxPermissionsService,
        private am: ActiveModulesService,
        private mta: HelperMedicalService,
        private store: Store,
    ) {
        // this.permissionsService.hasPermission('customer_details_canEdit').then(isEditable => {
        // });
    }

    // ngOnDestroy() {
    //     this.params.unsubscribe();
    // }

    public showDefaultMenu(): void {
        // this.clearTitle();
        // this.clearCustomerInMenu();
        // this.menuList.next([]);
    }
    public showTermsOrVisitMenu(id?): void {
        if (id) {
            const term = this.storage.getSelectedTerms(id);
            // Halo doktor obravnava
            if (
                this.am.isAM(AppModulesType.HELLO_DOCTOR) &&
                (term?.telemed || term?.phoneCall) &&
                (this.mta.isMTA(term?.reservationType) ||
                    term?.originUrl == OriginUrl.G100_01 ||
                    term?.originUrl == OriginUrl.G100_03 ||
                    term?.originUrl == OriginUrl.LAIF)
            ) {
                this.hdVisit[0].link = this.hdVisit[0].link.substring(0, 21);
                this.hdVisit[0].link += `/${id}`;
                this.hdVisit[1].link = this.hdVisit[1].link.substring(0, 18);
                this.hdVisit[1].link += `/${id}`;
                this.hdVisit[2].link = this.hdVisit[2].link.substring(0, 24);
                this.hdVisit[2].link += `/${id}`;
                this.hdVisit[3].link = this.hdVisit[3].link.substring(0, 29);
                this.hdVisit[3].link += `/${id}`;
                const validatedMenu = this.validateMenu(this.hdVisit);
                this.menuList.next(validatedMenu);
            }
            //Rezervacija
            else if (!term?.visitId) {
                this.terms[0].link = this.terms[0].link.substring(0, 18);
                this.terms[0].link += `/${id}`;
                this.terms[1].link = this.terms[1].link.substring(0, 29);
                this.terms[1].link += `/${id}`;
                this.terms[2].link = this.terms[2].link.substring(0, 22);
                this.terms[2].link += `/${id}`;
                const validatedMenu = this.validateMenu(this.terms);
                this.menuList.next(validatedMenu);
            }
            //Obravnava brez rezervacije
            else if (term?.visitId && !term?.prereservationId) {
                this.visit[0].link = this.visit[0].link.substring(0, 18);
                this.visit[0].link += `/${id}/visit`;
                this.visit[1].link = this.visit[1].link.substring(0, 24);
                this.visit[1].link += `/${id}/visit`;
                this.visit[2].link = this.visit[2].link.substring(0, 29);
                this.visit[2].link += `/${id}/visit`;
                const validatedMenu = this.validateMenu(this.visit);
                this.menuList.next(validatedMenu);
            }
            // // Obravnava z rezervacijo
            else if (
                term?.visitId &&
                term?.prereservationId &&
                term?.reservationType != ReservationType.HDD &&
                term?.reservationType != ReservationType.HDP &&
                term?.reservationType != ReservationType.SAVAD &&
                term?.reservationType != ReservationType.SAVAP &&
                term?.reservationType != ReservationType.MDFTD &&
                term?.reservationType != ReservationType.MDFTP &&
                term?.reservationType != ReservationType.WIDGET_VOUCHER &&
                term?.originUrl !== OriginUrl.G100_01 &&
                term?.originUrl !== OriginUrl.G100_03 &&
                term?.originUrl !== OriginUrl.LAIF
            ) {
                this.visit[0].link = this.visit[0].link.substring(0, 18);
                this.visit[0].link += `/${id}`;
                this.visit[1].link = this.visit[1].link.substring(0, 24);
                this.visit[1].link += `/${id}`;
                this.visit[2].link = this.visit[2].link.substring(0, 29);
                this.visit[2].link += `/${id}`;
                const validatedMenu = this.validateMenu(this.visit);
                this.menuList.next(validatedMenu);
            }
        }
        //Ne izvede (stara koda)
        else {
            const term = this.storage.getSelectedTerm();
            if (term && term?.visitId) {
                this.menuList.next(this.visit);
            } else {
                this.menuList.next(this.terms);
            }
        }
    }

    public showAccountingMenu(): void {
        this.setSubTitle(undefined);
        this.menuList.next(this.validateMenu(this.accounting, AppModulesType.ACCOUNTING));
        // this.menuList.next(this.terms);
    }

    public showMarketingMenu(): void {
        this.setSubTitle(undefined);
        this.menuList.next(this.validateMenu(this.marketing));
    }

    public showOimMenu(): void {
        this.setSubTitle(undefined);
        this.menuList.next(this.validateMenu(this.oimTracking));
    }

    public showAvailabilitMenu(): void {
        this.setSubTitle(undefined);
        this.menuList.next(this.validateMenu(this.availability));
    }

    public showInboxMenu(): MenuItem[] {
        this.setSubTitle(undefined);
        const validatedMenu: MenuItem[] = this.validateMenu(this.inbox, AppModulesType.INBOX);
        this.menuList.next(validatedMenu);
        return validatedMenu;
        // this.menuList.next(this.terms);
    }

    public showCustomerMenu(customerid: number): void {
        let index = 0;
        for (const route of this.customer) {
            const lastSlash = route.link?.lastIndexOf('/');
            this.customer[index].link = this.customer[index]?.link?.substring(0, lastSlash + 1);
            this.customer[index].link += customerid;
            index++;
        }
        //TODO opcija, da v primeru, da je stranka del SuperCustomerja, removamo CUSTOMERS/LINK element
        // if (!isSuperCustomer) {
        //     this.customer = this.customer.filter((el) => el.id !== 'CUSTOMERS/LINK');
        // }
        this.menuList.next(this.customer);
    }

    public showCustomersAndLegalEntitiesMenu(): void {
        this.setSubTitle(undefined);
        this.menuList.next(this.validateMenu(this.customersAndLegalEntities));
    }

    public showServicesMenu() {
        this.menuList.next(this.services);
    }

    public showHaloDocMenu() {
        this.menuList.next(this.validateMenu(this.haloDoc));
    }
    public showReportsMenu() {
        this.menuList.next(this.validateMenu(this.reports, AppModulesType.REPORTS));
    }
    public setSubTitle(text: string): void {
        this.subTitle.next(text);
    }

    public setCustomerInMenu(customer: Customer): void {
        this.customerInMenu.next(customer);
    }

    public resetMenu() {
        this.menuList.next([]);
    }

    public clearTitle(): void {
        this.subTitle.next(undefined);
    }
    public clearCustomerInMenu(): void {
        this.customerInMenu.next(null);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public validateMenu(menu: MenuItem[], module?: AppModulesType): any {
        const allPermissions = this.permissionsService.getPermissions();
        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const modules = this.store.selectSnapshot(BaseState.GetActiveModules);
        const res = menu?.filter((menuItem: MenuItem) => {
            if (menuItem.onlyTest) {
                if (!environment.test) {
                    return false;
                }
            }
            //ce ni primarnega menija poisci podmeni
            if (menuItem.tabMenuId) {
                menuItem.link = this.findFirstActiveTabMenu(this[menuItem.tabMenuId]);
            }

            if (menuItem?.module && !this.am.isAM(menuItem?.module)) {
                return false;
            }
            if (module) {
                const moduleData = modules.find(m => m.name == module);
                if (moduleData?.settings?.view) {
                    const view = moduleData?.settings?.view;
                    if (!view[menuItem.id]) {
                        return false;
                    }
                }
            }

            if (menuItem.rules) {
                if (allPermissions[menuItem.rules]) {
                    // ima dovoljenje za prikaz
                    return true;
                }
                // nima role - skrij
                return false;
            }

            return true;
        });
        return res;
    }

    private findFirstActiveTabMenu(tabMenu: MenuItem[]): string {
        const validated: MenuItem[] = this.validateMenu(tabMenu);
        if (validated.length > 0) {
            return validated[0].link;
        }
        return '';
    }
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public loadSettingsMenu(user: User): any {
        this.settingsMenuList[0].title = user.name;
        this.settingsMenuList[2].title = _.get(this.storage.getSelectedContractor(), 'name', '');
        return this.validateMenu(this.settingsMenuList);
    }

    public isAV(view: string): boolean {
        const contractorSettings: ContractorSettings = this.storage.getContractorSettings();
        return !_.get(contractorSettings, 'menu.hidden', []).includes(view);
    }

    public updateSubmenuItem(menuName: string, menuId: string, value: string) {
        let selectedMenu;
        switch (menuName) {
            case 'inbox':
                selectedMenu = this.inbox;
                break;
            case 'marketing':
                selectedMenu = this.marketing;
                break;
        }
        if (selectedMenu) {
            const menuItem: MenuItem = selectedMenu.find((el: MenuItem) => el.id == menuId);
            if (menuItem) {
                menuItem.displayValue = value;
            }
        }
    }
}
