import { Reservation } from '../model/reservation.model';

export class ReservationsHelper {
    public static sort(reservations: Reservation[]): Reservation[] {
        const sorted: Reservation[] = reservations.sort((a: Reservation, b: Reservation) => {
            let result = -1;
            if (a && b) {
                if (a.reservationFrom > b.reservationFrom) {
                    result = 1;
                }
            }

            return result;
        });

        return sorted;
    }
}
