import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PreReservation } from '../../../../_shared/model/preReservation.model';
import { HelperService } from '../../../../_shared/services';
import { HttpClientService } from '../../../../_shared/services/http-client.service';
import { Event } from './../../../../_shared/model/event.model';
import { Reservation } from './../../../../_shared/model/reservation.model';
import { EventUpdate } from './../../../../_shared/model/schedule/eventUpdate.model';
import { ReservationFilter } from './../../../../_shared/model/schedule/reservation-filter.model';
import { UnreadTerms } from './../../../../_shared/model/schedule/unread-terms.model';

@Injectable()
export class ReservationService {
    constructor(
        private httpClient: HttpClientService,
        private helper: HelperService,
    ) {}

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public replaceReservationCustomer(prereservationid: string, customerid: number): Observable<any> {
        return this.httpClient.put(this.helper.getApiUrl() + `/reservations/${prereservationid}/customers/${customerid}`);
    }

    // public updateReservation(prereservation: string, from: Date, to: Date, forceUpdate: boolean): Observable<any> {
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public updateReservation(prereservation: string, data: EventUpdate): Observable<any> {
        return this.httpClient.put(this.helper.getApiUrl() + '/reservations/' + prereservation, data);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public sendResevation(p: PreReservation): Observable<any> {
        return this.httpClient.post(this.helper.getApiUrl() + '/reservation', p);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public sendDraftResevation(p: PreReservation): Observable<any> {
        return this.httpClient.post(this.helper.getApiUrl() + '/reservation/draft', p);
    }

    /*
        {
            responseType: 'text'
        }
    */

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getReservationList(
        cid: number,
        sid: number,
        from: string,
        to: string,
        telemed?: boolean,
        showExaminations?: boolean,
        filterVisitNotConcluded?: boolean,
        showPortfolioPapers?: boolean,
        showInvoices?: boolean,
        showGroupInvoices?: boolean,
    ): Observable<Reservation[] | any> {
        let params = new HttpParams();
        params = from ? params.append('from', from as string) : params;
        params = to ? params.append('to', to as string) : params;
        params = telemed ? params.append('telemed', telemed as boolean) : params;
        params = showExaminations ? params.append('showExaminations', showExaminations as boolean) : params;
        params = filterVisitNotConcluded ? params.append('filterVisitNotConcluded', filterVisitNotConcluded as boolean) : params;
        params = showPortfolioPapers ? params.append('showPortfolioPapers', showPortfolioPapers as boolean) : params;
        params = showInvoices ? params.append('showInvoices', showInvoices as boolean) : params;
        params = showGroupInvoices ? params.append('showGroupInvoices', showGroupInvoices as boolean) : params;

        const url: string = this.helper.getApiUrl() + `/contractors/${cid}/subcontractors/${sid}/reservations`;
        return this.httpClient.get(url, { params });
    }
    // public getReservationForContractorList(cid: number, from: string, to: string, telemed?: boolean): Observable<any> {
    //     let url: string = this.helper.getApiUrl() + `/contractors/${cid}/reservations?from=${from}&to=${to}`;

    //     if (telemed) {
    //         url += `&telemed=${telemed}`;
    //     }
    //     return this.httpClient.get(url);
    // }

    public getReservationForContractorList(cid: number, from: string, to: string, filter?: ReservationFilter): Observable<Reservation[]> {
        let url: string = this.helper.getApiUrl() + `/contractors/${cid}/reservations?from=${from}&to=${to}`;
        if (filter) {
            Object.keys(filter).forEach(e => {
                url += `&${e}=${filter[e]}`;
            });
            // if (telemed) {
            //     url += `&telemed=${telemed}`;
            // }
        }
        return this.httpClient.get(url);
    }

    public getUnreadReservationForContractorList(cid: number): Observable<UnreadTerms[]> {
        const url: string = this.helper.getApiUrl(2) + `/contractors/${cid}/reservations/unread`;

        return this.httpClient.get(url);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getHolidays(year: any): Observable<any> {
        return this.httpClient.get(this.helper.getApiUrl() + '/holidays/year/' + year + '/country/SVN');
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public sendPreResevation(data: any): Observable<any> {
        return this.httpClient.post(this.helper.getApiUrl() + '/prereservation', data);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getReservation(cid: number, reservationid: string): Observable<Event | any> {
        return this.httpClient.get(this.helper.getApiUrl() + '/contractors/' + cid + '/reservations/' + reservationid);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getReservationRecurring(cid: number, reservationid: string): Observable<any> {
        return this.httpClient.get(this.helper.getApiUrl() + `/contractors/${cid}/reservations/${reservationid}?isRecurring=true`);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public copyReservation(reservationid: string, data: any): Observable<any> {
        return this.httpClient.post(this.helper.getApiUrl() + '/reservation/' + reservationid, data);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public sendTelemedUrl(contractorId: number, prereservationId: string): Observable<any> {
        return this.httpClient.post(`${this.helper.getTelemedUrl()}/contractors/${contractorId}/notify/${prereservationId}`, {});
    }
}
