import { Injectable } from '@angular/core';
import { ObjectState } from '@meddev/fe-shared';
import { Action, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { throwError } from 'rxjs';
import { catchError, first, switchMap, tap } from 'rxjs/operators';
import { SettingsService } from '../../../theme/pages/home/_services';
import { ExaminationGroupCreate } from '../../model/settings/examination-group-create.model';
import { ExaminationGroup } from '../../model/settings/examination-group.model';
import { ExaminationsClientService } from '../../services/clients/examinations-client.service';
import { BaseState } from '../base/base.state';
import { ExaminationGroupsStateModel } from './examination-groups-state.model';
import {
    AddExaminationsToGroup,
    CreateExaminationGroup,
    DeleteExaminationGroup,
    LoadExaminationGroups,
    LoadExaminations,
    RemoveExaminationFromGroup,
    SetDefaultState,
    SetSelectedExaminationGroup,
    SetSelectedExaminationGroupById,
    UpdateExaminationGroup,
    UpdateSelectedExaminationGroup,
} from './examination-groups.actions';

const EXAMINATION_GROUPS_STATE_TOKEN: StateToken<ExaminationGroupsStateModel> = new StateToken('examinationGroupsState');

const DEFAULT_STATE: ExaminationGroupsStateModel = {
    examinationGroups: { state: ObjectState.UNINIT, value: [] },
    selectedExaminationGroupId: null,
    allExaminations: { state: ObjectState.UNINIT, value: [] },
};

@State<ExaminationGroupsStateModel>({
    name: EXAMINATION_GROUPS_STATE_TOKEN,
    defaults: DEFAULT_STATE,
})
@Injectable()
export class ExaminationGroupsState {
    constructor(
        private settingsService: SettingsService,
        private examinationsClientService: ExaminationsClientService,
        private store: Store,
    ) {}

    @Selector([EXAMINATION_GROUPS_STATE_TOKEN])
    public static examinationGroups$(state: ExaminationGroupsStateModel) {
        return state.examinationGroups;
    }

    @Selector([EXAMINATION_GROUPS_STATE_TOKEN])
    public static selectedExaminationGroup$(state: ExaminationGroupsStateModel) {
        return state.examinationGroups.value.find(group => group.id === state.selectedExaminationGroupId);
    }

    @Selector([EXAMINATION_GROUPS_STATE_TOKEN])
    public static allExaminations$(state: ExaminationGroupsStateModel) {
        return state.allExaminations;
    }

    @Action(SetDefaultState)
    public setDefaultState(ctx: StateContext<ExaminationGroupsStateModel>): void {
        ctx.setState(DEFAULT_STATE);
    }

    @Action(LoadExaminationGroups)
    public loadExaminationGroups(ctx: StateContext<ExaminationGroupsStateModel>) {
        return this.store.select(BaseState.activeContractorId).pipe(
            tap(() => ctx.patchState({ examinationGroups: { value: ctx.getState().examinationGroups.value, state: ObjectState.LOADING } })),
            switchMap(contractorId =>
                this.settingsService.getExaminationGroups(contractorId).pipe(
                    tap(examinationGroups => {
                        ctx.patchState({ examinationGroups: { state: ObjectState.VALID, value: examinationGroups } });
                    }),
                ),
            ),
        );
    }

    @Action(LoadExaminations)
    public loadExaminations(ctx: StateContext<ExaminationGroupsStateModel>) {
        return this.store.select(BaseState.activeContractorId).pipe(
            tap(() => ctx.patchState({ allExaminations: { value: ctx.getState().allExaminations.value, state: ObjectState.LOADING } })),
            switchMap(contractorId =>
                this.examinationsClientService.getContractorExaminations(contractorId).pipe(
                    tap(examinations => {
                        ctx.patchState({ allExaminations: { state: ObjectState.VALID, value: examinations } });
                    }),
                ),
            ),
        );
    }

    @Action(SetSelectedExaminationGroup)
    public setSelectedExaminationGroup(ctx: StateContext<ExaminationGroupsStateModel>, action: SetSelectedExaminationGroup): void {
        ctx.patchState({ selectedExaminationGroupId: action.selectedExaminationGroup?.id });
    }

    @Action(SetSelectedExaminationGroupById)
    public setSelectedExaminationGroupById(ctx: StateContext<ExaminationGroupsStateModel>, action: SetSelectedExaminationGroupById): void {
        ctx.patchState({ selectedExaminationGroupId: action.selectedExaminationGroupId });
    }

    @Action(CreateExaminationGroup)
    public createExaminationGroup(ctx: StateContext<ExaminationGroupsStateModel>, action: CreateExaminationGroup) {
        return this.store.select(BaseState.activeContractorId).pipe(
            first(),

            switchMap(contractorId =>
                this.settingsService.addExaminationGroup(contractorId, action.examinationGroup).pipe(
                    tap(() => {
                        ctx.dispatch(new LoadExaminationGroups());
                    }),
                ),
            ),
        );
    }

    @Action(UpdateExaminationGroup)
    public updateExaminationGroup(ctx: StateContext<ExaminationGroupsStateModel>, action: UpdateExaminationGroup) {
        return this.store.select(BaseState.activeContractorId).pipe(
            first(),
            switchMap(contractorId =>
                this.settingsService.updateExaminationGroup(contractorId, action.examinationGroupId, action.examinationGroup).pipe(
                    tap(() => {
                        const state = ctx.getState();
                        const examinations = state.allExaminations.value?.filter(exam => action.examinationGroup.examinationIds.includes(exam.id));

                        const updatedGroups = state.examinationGroups.value?.map(group =>
                            group.id === action.examinationGroupId ? { ...group, name: action.examinationGroup.name, examinations } : group,
                        );

                        ctx.patchState({
                            examinationGroups: { ...state.examinationGroups, value: updatedGroups },
                        });
                    }),
                    catchError(err => throwError(() => err)),
                ),
            ),
        );
    }

    @Action(UpdateSelectedExaminationGroup)
    public updateSelectedExaminationGroup(ctx: StateContext<ExaminationGroupsStateModel>, action: UpdateSelectedExaminationGroup) {
        const selectedExaminationGroup = ctx.getState().examinationGroups.value.find(group => group.id === ctx.getState().selectedExaminationGroupId);
        return ctx.dispatch(new UpdateExaminationGroup(selectedExaminationGroup.id, action.examinationGroup));
    }

    @Action(DeleteExaminationGroup)
    public deleteExaminationGroup(ctx: StateContext<ExaminationGroupsStateModel>, action: DeleteExaminationGroup) {
        return this.store.select(BaseState.activeContractorId).pipe(
            first(),

            switchMap(contractorId =>
                this.settingsService.deleteExaminationGroup(contractorId, action.examinationGroupId).pipe(
                    tap(() => {
                        ctx.patchState({
                            examinationGroups: {
                                state: ObjectState.VALID,
                                value: ctx.getState().examinationGroups.value.filter(group => group.id !== action.examinationGroupId),
                            },
                        });
                        if (ctx.getState().selectedExaminationGroupId === action.examinationGroupId) {
                            ctx.patchState({ selectedExaminationGroupId: null });
                        }
                    }),
                    catchError(err => throwError(() => err)),
                ),
            ),
        );
    }

    @Action(AddExaminationsToGroup)
    public addExaminationsToGroup(ctx: StateContext<ExaminationGroupsStateModel>, action: AddExaminationsToGroup) {
        const state = ctx.getState();
        const selectedGroup = state.examinationGroups.value.find(group => group.id === state.selectedExaminationGroupId);

        if (!selectedGroup) {
            throw new Error('No selected examination group to add an examination to.');
        }

        const updatedSelectedGroup: ExaminationGroup = {
            ...selectedGroup,
            examinations: [
                ...(selectedGroup.examinations || []),
                ...action.examinations.filter(e => !selectedGroup.examinations.map(ex => ex.id).includes(e.id)),
            ],
        };

        const updatedGroups = state.examinationGroups.value.map(group => (group.id === selectedGroup.id ? updatedSelectedGroup : group));

        ctx.patchState({
            examinationGroups: { state: ObjectState.VALID, value: updatedGroups },
        });

        const examinationGroupUpdate: ExaminationGroupCreate = {
            name: updatedSelectedGroup.name,
            examinationIds: updatedSelectedGroup.examinations.map(e => e.id),
        };

        return ctx.dispatch(new UpdateSelectedExaminationGroup(examinationGroupUpdate));
    }

    @Action(RemoveExaminationFromGroup)
    public removeExaminationFromGroup(ctx: StateContext<ExaminationGroupsStateModel>, action: RemoveExaminationFromGroup) {
        const state = ctx.getState();
        const selectedGroup = state.examinationGroups.value.find(group => group.id === state.selectedExaminationGroupId);

        if (!selectedGroup) {
            throw new Error('No selected examination group to remove an examination from.');
        }

        const updatedSelectedGroup = {
            ...selectedGroup,
            examinations: selectedGroup.examinations.filter(examination => examination.id !== action.examinationId),
        };

        const updatedGroups = state.examinationGroups.value.map(group => (group.id === selectedGroup.id ? updatedSelectedGroup : group));

        ctx.patchState({
            examinationGroups: { state: ObjectState.VALID, value: updatedGroups },
        });

        const examinationGroupUpdate: ExaminationGroupCreate = {
            name: updatedSelectedGroup.name,
            examinationIds: updatedSelectedGroup.examinations.map(e => e.id),
        };

        return ctx.dispatch(new UpdateSelectedExaminationGroup(examinationGroupUpdate));
    }
}
