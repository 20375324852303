import { Injectable } from '@angular/core';
import { ObjectState, StatefullValue } from '@meddev/fe-shared';
import { Action, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import moment from 'moment';
import { catchError, forkJoin, map, mergeMap, Observable, of, tap } from 'rxjs';
import { ReservationService } from '../../../theme/pages/home/_services';
import { appConstants } from '../../constants/app-constants.const';
import { DATE_CONST } from '../../constants/date-constants.const';
import { AppModulesType } from '../../enums/appModulesType.enum';
import { ReservationsHelper } from '../../helper/reservations-helper';
import { GuiUserContractorSettings, Subcontractor } from '../../model';
import { Reservation } from '../../model/reservation.model';
import { ActiveModulesService } from '../../services/activeModules.service';
import { BaseState } from '../base/base.state';
import { KanbanStateModel } from './kanban-state.model';
import {
    LoadPendingReservations,
    LoadTodaysReservations,
    SetReservationKanbanStatus,
    SetReservationVisitId,
    UpdateReservation,
} from './kanban.actions';

const KANBAN_STATE_TOKEN: StateToken<KanbanStateModel> = new StateToken('kanbanstate');

const DEFAULT_STATE: KanbanStateModel = {
    todaysReservations: { state: ObjectState.UNINIT, value: null } as StatefullValue<Reservation[]>,
    pendingReservations: { state: ObjectState.UNINIT, value: null } as StatefullValue<Reservation[]>,
    selectedSubcontractors: [],
};

@State<KanbanStateModel>({
    name: KANBAN_STATE_TOKEN,
    defaults: DEFAULT_STATE,
    children: [],
})
@Injectable()
export class KanbanState {
    constructor(
        private reservationService: ReservationService,
        private store: Store,
        private activeModulesService: ActiveModulesService,
    ) {}

    @Selector([KANBAN_STATE_TOKEN])
    static todaysReservations$(state: KanbanStateModel) {
        return state.todaysReservations;
    }

    @Selector([KANBAN_STATE_TOKEN])
    static pendingReservations$(state: KanbanStateModel) {
        return state.pendingReservations;
    }

    @Action(LoadTodaysReservations)
    loadTodaysReservations(ctx: StateContext<KanbanStateModel>) {
        const isMdpsModuleActive = this.activeModulesService.isAM(AppModulesType.OCCUPATIONAL_MEDICINE);
        return this.store.selectOnce(BaseState.GetUserSettings).pipe(
            tap((res: GuiUserContractorSettings) => {
                ctx.patchState({ selectedSubcontractors: <Subcontractor[]>res.calendar.subconstractors });
            }),
            mergeMap((res: GuiUserContractorSettings) => {
                const apiCallst: Observable<Reservation[]>[] = res.calendar.subconstractors.map((subc: Subcontractor) => {
                    return this.reservationService
                        .getReservationList(
                            this.store.selectSnapshot(BaseState.activeContractorId),
                            subc.id,
                            moment().format(DATE_CONST.format.date.api),
                            moment().format(DATE_CONST.format.date.api),
                            false,
                            isMdpsModuleActive,
                            false,
                            true,
                            true,
                            true,
                        )
                        .pipe(
                            catchError(() => {
                                return of([]);
                            }),
                            map((reservations: Reservation[]) => {
                                return reservations.filter((res: Reservation) => !res.title);
                            }),
                        );
                });
                return forkJoin(apiCallst);
            }),
            map((reservationsArrays: Reservation[][]) => ReservationsHelper.sort(reservationsArrays.flat())),
            tap((res: Reservation[]) => {
                ctx.patchState({ todaysReservations: { state: ObjectState.VALID, value: res } });
            }),
        );
    }

    @Action(LoadPendingReservations)
    loadPendingReservations(ctx: StateContext<KanbanStateModel>) {
        const isMdpsModuleActive = this.activeModulesService.isAM(AppModulesType.OCCUPATIONAL_MEDICINE);
        return this.store.selectOnce(BaseState.GetUserSettings).pipe(
            tap((res: GuiUserContractorSettings) => {
                ctx.patchState({ selectedSubcontractors: <Subcontractor[]>res.calendar.subconstractors });
            }),
            mergeMap((res: GuiUserContractorSettings) => {
                const apiCallst: Observable<Reservation[]>[] = res.calendar.subconstractors.map((subc: Subcontractor) => {
                    return this.reservationService
                        .getReservationList(
                            this.store.selectSnapshot(BaseState.activeContractorId),
                            subc.id,
                            moment().subtract(appConstants.pendingKanbanItemsAge, 'days').format(DATE_CONST.format.date.api),
                            moment().subtract(1, 'day').format(DATE_CONST.format.date.api),
                            false,
                            isMdpsModuleActive,
                            true,
                            true,
                            true,
                            true,
                        )
                        .pipe(
                            catchError(() => {
                                return of([]);
                            }),
                            map((reservations: Reservation[]) => {
                                return reservations.filter((res: Reservation) => !res.title);
                            }),
                        );
                });
                return forkJoin(apiCallst);
            }),
            map((reservationsArrays: Reservation[][]) => ReservationsHelper.sort(reservationsArrays.flat())),
            tap((res: Reservation[]) => {
                ctx.patchState({ pendingReservations: { state: ObjectState.VALID, value: res } });
            }),
        );
    }

    @Action(UpdateReservation)
    updateReservation(ctx: StateContext<KanbanStateModel>, action: UpdateReservation) {
        const { reservationId, payload } = action;
        return this.reservationService.updateReservation(reservationId, payload).pipe(
            tap(() => {
                this.store.dispatch(new SetReservationKanbanStatus(reservationId, payload.kanbanStatus));
            }),
        );
    }

    @Action(SetReservationVisitId)
    setReservationVisitId(ctx: StateContext<KanbanStateModel>, action: SetReservationVisitId) {
        const { reservationId, visitId } = action;
        const todaysReservations = ctx.getState().todaysReservations.value;
        const updatedReservations: Reservation[] = todaysReservations.map(reservation => {
            if (reservation.prereservationId === reservationId) {
                return { ...reservation, visitId, deserialize: reservation.deserialize };
            }
            return reservation;
        });
        ctx.patchState({ todaysReservations: { state: ObjectState.VALID, value: updatedReservations } });
    }

    @Action(SetReservationKanbanStatus)
    setReservationKanbanStatus(ctx: StateContext<KanbanStateModel>, action: SetReservationKanbanStatus) {
        const { reservationId, kanbanStatus } = action;
        const todaysReservations = ctx.getState().todaysReservations.value;
        const updatedReservations: Reservation[] = todaysReservations.map(reservation => {
            if (reservation.prereservationId === reservationId) {
                return { ...reservation, kanbanStatus, deserialize: reservation.deserialize };
            }
            return reservation;
        });
        ctx.patchState({ todaysReservations: { state: ObjectState.VALID, value: updatedReservations } });
    }
}
