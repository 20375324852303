import { ReservationType } from './../enums/schedule/reservationType.enum';
import { GroupInvoice } from './accounting/groupInvoice/groupInvoice.model';
import { InvoiceReport } from './accounting/invoiceReport/invoiceReport.model';
import { Asset } from './asset.model';
import { Contractor } from './contractor.model';
import { Customer } from './customer.model';
import { Serializable } from './deserialize.service';
import { ReservationExaminationResponseDto } from './examinations/examination-response-dto';
import { PortfolioPaper } from './portfolio/portfolioPaper.model';
import { Service } from './service.model';
import { ServiceGroup } from './serviceGroup.model';

export class Reservation implements Serializable<Reservation> {
    reservationFrom: Date;
    reservationTo: Date;
    visitId: number;
    aggregateId: number;
    prereservationId: string;
    status: number;
    // 1: prereservation, 2: confirmed, 3: canceled contractor, 4: canceled patient,
    // 5: canceled business_user, 6: obsolete, 7: confirmed draft reservation, 8: recurring event, 9: canceled draft, 10: not confirmed draft reservation
    service: Service;
    contractor: Contractor;
    customer: Customer;
    asset: Asset;
    visitEndDate: Date;
    comment: string;
    customTagId: number;
    telemed: boolean;
    kanbanStatus: string;
    noShow: boolean;
    title: string;
    recurringId: string;
    serviceGroup: ServiceGroup;
    reservationType: ReservationType;
    phoneCall: boolean;
    examinations?: ReservationExaminationResponseDto[];
    portfolioPapers?: PortfolioPaper[];
    invoiceReports?: InvoiceReport[];
    groupInvoiceConfirmations?: GroupInvoice[];

    deserialize(input) {
        this.reservationFrom = input.reservationFrom;
        this.reservationTo = input.reservationTo;
        this.visitId = input.visitId;
        this.aggregateId = input.aggregateId;
        this.prereservationId = input.prereservationId;
        this.status = input.status;
        this.comment = input.comment;
        if (input.service) {
            this.service = new Service().deserialize(input.service);
        }
        if (input.contractor) {
            this.contractor = new Contractor().deserialize(input.contractor);
        }
        if (input.customer) {
            this.customer = new Customer().deserialize(input.customer);
        }
        if (input.asset) {
            this.asset = new Asset().deserialize(input.asset);
        }
        this.customTagId = input.customTagId;
        this.telemed = input.telemed;
        this.kanbanStatus = input.kanbanStatus;
        this.noShow = input.noShow;
        this.title = input.title;
        this.recurringId = input.recurringId;
        this.serviceGroup = input.serviceGroup;
        this.reservationType = input.reservationType;
        this.phoneCall = input.phoneCall;
        if (input.examinations) {
            this.examinations = input.examinations;
        }
        if (input.portfolioPapers) {
            this.portfolioPapers = input.portfolioPaters;
        }
        if (input.invoiceReports) {
            this.invoiceReports = input.invoiceReports;
        }
        if (input.groupInvoiceConfirmations) {
            this.groupInvoiceConfirmations = input.groupInvoiceConfirmations;
        }
        return this;
    }
}
