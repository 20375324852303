import { ExaminationResponseDto } from '../../model/examinations/examination-response-dto';
import { ExaminationGroupCreate } from '../../model/settings/examination-group-create.model';
import { ExaminationGroup } from '../../model/settings/examination-group.model';

export class SetDefaultState {
    public static readonly type = 'SetDefaultState';
}

export class LoadExaminationGroups {
    public static readonly type = 'LoadExaminationGroups';
}

export class SetSelectedExaminationGroup {
    public static readonly type = 'SetSelectedExaminationGroup';
    constructor(public readonly selectedExaminationGroup: ExaminationGroup) {}
}

export class SetSelectedExaminationGroupById {
    public static readonly type = 'SetSelectedExaminationGroupById';
    constructor(public readonly selectedExaminationGroupId: number) {}
}

export class CreateExaminationGroup {
    public static readonly type = 'SaveExaminationGroups';
    constructor(public readonly examinationGroup: ExaminationGroupCreate) {}
}

export class UpdateExaminationGroup {
    public static readonly type = 'UpdateExaminationGroup';
    constructor(
        public readonly examinationGroupId: number,
        public readonly examinationGroup: ExaminationGroupCreate,
    ) {}
}

export class UpdateSelectedExaminationGroup {
    public static readonly type = 'SaveSelectedExaminationGroup';
    constructor(public readonly examinationGroup: ExaminationGroupCreate) {}
}

export class DeleteExaminationGroup {
    public static readonly type = 'DeleteExaminationGroup';
    constructor(public readonly examinationGroupId: number) {}
}

export class LoadExaminations {
    public static readonly type = 'LoadExaminations';
}

export class AddExaminationsToGroup {
    public static readonly type = 'AddExaminationToGroup';
    constructor(public readonly examinations: ExaminationResponseDto[]) {}
}

export class RemoveExaminationFromGroup {
    public static readonly type = 'RemoveExaminationFromGroup';
    constructor(public readonly examinationId: number) {}
}
