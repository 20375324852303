import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { forkJoin, Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { BasicDocumentViewerSettings } from '../../../theme/basic-components/basic-document-viewer/model/basic-document-viewer-settings';
import { AppModulesType } from '../../enums/appModulesType.enum';
import { ExaminationsManager } from '../../managers/examinations-manager';
import { ExaminationResponseDto, ReservationExaminationResponseDto } from '../../model/examinations/examination-response-dto';
import { ActiveModulesService } from '../../services/activeModules.service';
import { ExaminationsClientService } from '../../services/clients/examinations-client.service';
import { Document } from './../../../_shared/model/document.model';
import {
    LoadExaminations,
    SaveReservationExaminations,
    SaveSelectedExamination,
    SetDefaultState,
    SetPreReservation,
    SetSelectedExamination,
} from './examinations-actions';
import { ExaminationStateModel } from './examinations-state.model';

const EXAMINATIONSSTATE_TOKEN: StateToken<ExaminationStateModel> = new StateToken('examinationstate');

const DEFAULT_STATE: ExaminationStateModel = {
    selectedReservationExaminationId: null,
    contractorId: null,
    preReservationId: null,
    contractorExaminations: [],
    examinationsForReservation: [],
    selectedExaminationFields: null,
    documentViewerDocuments: [],
    mdpsSurveyDocumentViewerSettings: null,
    examinationDocumentViewerSettings: null,
};

@State<ExaminationStateModel>({
    name: EXAMINATIONSSTATE_TOKEN,
    defaults: DEFAULT_STATE,
    children: [],
})
@Injectable()
export class ExaminationState {
    private examinationsManager: ExaminationsManager;
    constructor(
        private examinationsClientService: ExaminationsClientService,
        private am: ActiveModulesService,
        private store: Store,
    ) {
        this.examinationsManager = new ExaminationsManager(this.examinationsClientService, am);
    }

    @Selector([EXAMINATIONSSTATE_TOKEN])
    public static preReservationId$(state: ExaminationStateModel): string | undefined {
        return state.preReservationId;
    }

    @Selector([EXAMINATIONSSTATE_TOKEN])
    public static contractorId$(state: ExaminationStateModel): number | undefined {
        return state.contractorId;
    }

    @Selector([EXAMINATIONSSTATE_TOKEN])
    public static selectedReservationExaminationId$(state: ExaminationStateModel): number | undefined {
        return state.selectedReservationExaminationId;
    }
    @Selector([EXAMINATIONSSTATE_TOKEN])
    public static contractorExaminations$(state: ExaminationStateModel): ExaminationResponseDto[] | undefined {
        return state.contractorExaminations;
    }
    @Selector([EXAMINATIONSSTATE_TOKEN])
    public static examinationsForReservation$(state: ExaminationStateModel): ReservationExaminationResponseDto[] | undefined {
        return state.examinationsForReservation;
    }
    @Selector([EXAMINATIONSSTATE_TOKEN])
    public static selectedExaminationFields$(state: ExaminationStateModel): ReservationExaminationResponseDto | undefined {
        return state.selectedExaminationFields;
    }
    @Selector([EXAMINATIONSSTATE_TOKEN])
    public static documentViewerDocuments$(state: ExaminationStateModel): Document[] | undefined {
        return state.documentViewerDocuments;
    }
    @Selector([EXAMINATIONSSTATE_TOKEN])
    public static mdpsSurveyDocumentViewerSettings$(state: ExaminationStateModel): BasicDocumentViewerSettings | undefined {
        return state.mdpsSurveyDocumentViewerSettings;
    }
    @Selector([EXAMINATIONSSTATE_TOKEN])
    public static examinationDocumentViewerSettings$(state: ExaminationStateModel): BasicDocumentViewerSettings | undefined {
        return state.examinationDocumentViewerSettings;
    }

    @Action(SetDefaultState)
    public ClearState(ctx: StateContext<ExaminationStateModel>) {
        ctx.patchState(DEFAULT_STATE);
    }

    @Action(LoadExaminations)
    public LoadExaminations(ctx: StateContext<ExaminationStateModel>) {
        return of(null).pipe(
            switchMap(() =>
                forkJoin([
                    this.examinationsManager.getContractorExaminations$(ctx.getState().contractorId),
                    this.examinationsManager.getReservationExaminations$(ctx.getState().preReservationId, ctx.getState().contractorId),
                ]),
            ),
            tap(([contractorExaminations, examinationsForReservation]) => {
                const documentViewerDocuments = this.am.isAM(AppModulesType.OCCUPATIONAL_MEDICINE)
                    ? examinationsForReservation.map(exam => exam.documents).flat()
                    : [];
                const mdpsSurveyDocumentViewerSettings = this.examinationsManager.createMdpsSurveyViewerSettings(
                    ctx.getState().contractorId,
                    examinationsForReservation,
                );
                ctx.patchState({ contractorExaminations, examinationsForReservation, documentViewerDocuments, mdpsSurveyDocumentViewerSettings });
            }),
        );
    }

    @Action(SetPreReservation)
    public SetPreReservation(ctx: StateContext<ExaminationStateModel>, action: SetPreReservation): Observable<void> {
        return of(null).pipe(
            tap(() => {
                ctx.patchState({ preReservationId: action.preReservationId, contractorId: action.contractorId });
            }),
            switchMap(() => ctx.dispatch(new LoadExaminations())),
        );
    }

    @Action(SetSelectedExamination)
    public SetSelectedExamination(ctx: StateContext<ExaminationStateModel>, action: SetSelectedExamination) {
        if (action.selectedReservationExaminationId) {
            ctx.patchState({
                selectedReservationExaminationId: action.selectedReservationExaminationId,
            });

            const examinationDocumentViewerSettings = this.examinationsManager.createSelectedExaminationDocumentViewerSettings(
                ctx.getState().preReservationId,
                ctx.getState().contractorId,
                ctx.getState().selectedReservationExaminationId,
                this.store.select(ExaminationState.examinationsForReservation$),
                () => {
                    ctx.dispatch(new LoadExaminations());
                },
            );

            ctx.patchState({
                selectedReservationExaminationId: action.selectedReservationExaminationId,
                selectedExaminationFields: ctx
                    .getState()
                    .examinationsForReservation.find(examination => examination.id === action.selectedReservationExaminationId),
                examinationDocumentViewerSettings: examinationDocumentViewerSettings,
            });
        }
    }

    @Action(SaveReservationExaminations)
    public SaveReservationExaminations(ctx: StateContext<ExaminationStateModel>, action: SaveReservationExaminations) {
        return of(null).pipe(
            switchMap(() =>
                this.examinationsManager.saveReservationExaminations$(
                    ctx.getState().examinationsForReservation,
                    ctx.getState().preReservationId,
                    ctx.getState().contractorId,
                    action.items,
                ),
            ),
            tap(() => ctx.dispatch(new LoadExaminations())),
        );
    }

    @Action(SaveSelectedExamination)
    public SaveSelectedExamination(ctx: StateContext<ExaminationStateModel>, action: SaveSelectedExamination) {
        return of(null).pipe(
            switchMap(() =>
                this.examinationsManager.saveSelectedExamination(
                    ctx.getState().selectedExaminationFields,
                    ctx.getState().preReservationId,
                    ctx.getState().contractorId,
                    ctx.getState().selectedReservationExaminationId,
                    action.fieldsWithValues,
                    action.comment,
                ),
            ),
            switchMap(() => ctx.dispatch(new LoadExaminations())),
        );
    }
}
