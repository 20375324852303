import { EventUpdate } from '../../model';

export class LoadTodaysReservations {
    static readonly type = '[Kanban] Load Todays Reservations';
}

export class LoadPendingReservations {
    static readonly type = '[Kanban] Load Pending Reservations';
}

export class UpdateReservation {
    static readonly type = '[Kanban] Update Reservation';
    constructor(
        public readonly reservationId: string,
        public readonly payload: EventUpdate,
    ) {}
}

export class SetReservationVisitId {
    static readonly type = '[Kanban] Set Reservation Visit Id';
    constructor(
        public readonly reservationId: string,
        public readonly visitId: number,
    ) {}
}

export class SetReservationKanbanStatus {
    static readonly type = '[Kanban] Set Reservation Kanban Status';
    constructor(
        public readonly reservationId: string,
        public readonly kanbanStatus: string,
    ) {}
}
